<template>
  <div>
    <b-container>
      <h1 class="mt-3">Stage 4</h1>
      <h3>Assembly Merge and Quality Control with RTDmaker</h3>
      <div class="text-left" style="margin: 10px 0px">
        <p>The analyses from Stage 1-3 are performed parallelly for each sample. RTDmaker is used to merge the transcript assemblies of individual samples and carry out a number of filtering to identify and remove the mis-assembled transcripts, including:
          <ul>
            <li>Redundant transcripts.</li>
            <li>Unstranded transcripts.</li>
            <li>Transcript with poorly supported splice junctions.</li>
            <li>Fragmented transcripts.</li>
            <li>Spurious mono-exonic antisense transcript fragments.</li>
            <li>Chimeric transcripts.</li>
            <li>Random lowly expressed transcripts.</li>
          </ul>
        </p>
        <div class="text-center">
          <img src="images/rtdbox_maker.png"/>
        </div>
        <p>RTDmaker can integrate available transcript annotations to the final assembled RTD. Please provide the gtf file names of the annotations in the metatable, e.g.
              <!-- Indentation and layout of below text is replicated on screen, DO NOT CHANGE! -->
              <pre><samp>
    Rubus_idaeus_genome_v1.fa
    Available_transcript_annotation1.gtf
    Available_transcript_annotation2.gtf
    ERR2784286,ERR2784286_1.fastq.gz,ERR2784286_2.fastq.gz
    ERR2784287,ERR2784287_1.fastq.gz,ERR2784287_2.fastq.gz
              </samp></pre>
            </p>

            <p>Parameter settings:
              <ul>
                <li>Minimum number of mapped reads to support a splice junction: A splice junction must be supported by at least this number of reads.</li>
                <li>Minimum number of samples to support the splice junction: The minimum reads support for the splice junction must occur at least this number of samples. </li>
                <li>Minimum TPM abundance for expressed transcripts: An expressed transcript must have at least this number of TPMs.</li>
                <li>Minimum number of samples for expressed transcript consideration: The TPM support for an expressed transcript must occur at least this number of samples.</li>
                <li>Fragment transcript length: A fragment transcript in the gene model will be filtered if the length is less than a defined percentage of the gene length. Value must be within 0 and 1.</li>
                <li>Monoexonic-antisense fragment transcript length: A monoexonic-antisense transcript in the gene model will be filtered if the transcript is in the opposite strand and the length is less than this percentage of the gene length. Value must be within 0 and 1.</li>
                <li>ID prefix: Prefix to assign to the genes and transcripts IDs in the final RTD.</li>
              </ul>
            </p>
      </div>
      <div style="margin: 10px 0px">
        <p class="m-0 font-weight-bold">Select the folder which contains the relevant assembly results:</p>
          <b-alert show variant="danger" v-if=errorMsg>{{ errorMsg }}
            <div v-if=(errorShowRefresh)>Please refresh this page and try again, or contact an administrator if you continue to see this error.</div>
          </b-alert>
     
        <b-form @submit.prevent="submitJob">
          <folder-browser :folders="folders" :selectedFolder="selectedFolder"
            @select-folder="folderSelected">
          </folder-browser>

          <b-row class="input-row">
            <b-input-group>
              <b-input-group-prepend class="input-label">
                  <b-input-group-text class="bg-white w-100">Minimum number of mapped reads to support a splice junction:</b-input-group-text>
                </b-input-group-prepend>
              <div class="input-box" v-b-tooltip.hover.html :title="SJReadsCutOffTip">
                <b-form-input id="SJReadsCutOffInput" v-model="SJReadsCutOff" type="number" min="0" class="bg-secondary"></b-form-input>
              </div>
            </b-input-group>          
          </b-row>

          <b-row class="input-row">
            <b-input-group>
              <b-input-group-prepend class="input-label">
                <b-input-group-text class="bg-white w-100">Minimum number of samples to support the splice junction:</b-input-group-text>
              </b-input-group-prepend>
              <div class="input-box" v-b-tooltip.hover.html :title="SJReadsSampleTip">
                <b-form-input id="SJReadsSampleInput" v-model="SJReadsSample" type="number" min="0" class="bg-secondary"></b-form-input>
              </div>
            </b-input-group>
          </b-row>
            
          <b-row class="input-row">
            <b-input-group>
              <b-input-group-prepend class="input-label">
                <b-input-group-text class="bg-white w-100">Minimum TPM abundance for expressed transcripts:</b-input-group-text>
              </b-input-group-prepend>
              <div class="input-box" v-b-tooltip.hover.html :title="TPMCutOffTip">
                <b-form-input id="TPMCutOffInput" v-model="TPMCutOff" type="number" min="0" class="bg-secondary"></b-form-input>
              </div>
            </b-input-group>
          </b-row>

          <b-row class="input-row">
            <b-input-group>
              <b-input-group-prepend class="input-label">
                <b-input-group-text class="bg-white w-100">Minimum number of samples for expressed transcript consideration:</b-input-group-text>
              </b-input-group-prepend>
              <div class="input-box" v-b-tooltip.hover.html :title="TPMSampleTip">
                <b-form-input id="TPMSampleInput" v-model="TPMSample" type="number" min="0" class="bg-secondary"></b-form-input>
              </div>
            </b-input-group>
          </b-row>

          <b-row class="input-row">
            <b-input-group>
              <b-input-group-prepend class="input-label">
                <b-input-group-text class="bg-white w-100">Fragment transcript length:</b-input-group-text>
              </b-input-group-prepend>
              <div class="input-box" v-b-tooltip.hover.html :title="fragmentLenTip">
                <b-form-input id="fragmentLenInput" v-model="fragmentLen" type="number" min="0" max="1" step="0.1" class="bg-secondary"></b-form-input>
              </div>
            </b-input-group>
          </b-row>

          <b-row class="input-row">
            <b-input-group>
              <b-input-group-prepend class="input-label">
                <b-input-group-text class="bg-white w-100">Monoexonic-antisense fragment transcript length:</b-input-group-text>
              </b-input-group-prepend>
              <div class="input-box" v-b-tooltip.hover.html :title="antiSenseLenTip">
                <b-form-input id="antiSenseLenInput" v-model="antiSenseLen" type="number" min="0" max="1" step="0.1" class="bg-secondary"></b-form-input>
              </div>
            </b-input-group>
          </b-row>

        <b-row class="input-row">
            <b-input-group>
              <b-input-group-prepend class="input-label">
                <b-input-group-text class="bg-white w-100">ID prefix:</b-input-group-text>
              </b-input-group-prepend>
              <div class="input-box" v-b-tooltip.hover.html :title="prefixTip">
                <b-form-input id="prefixInput" v-model="prefix" type="text" class="bg-secondary"></b-form-input>
              </div>
            </b-input-group>
          </b-row>

          <b-form-checkbox
            id="NameCheck"
            v-model="nameCheck"
            name="NameCheck"
          >
            Tick if you want to use a custom name for the results folder.
          </b-form-checkbox>

          <b-row v-if="nameCheck" class="input-row">
            <b-input-group>
              <b-input-group-prepend class="input-label">
                <b-input-group-text class="bg-white w-100">Custom folder name:</b-input-group-text>
              </b-input-group-prepend>
              <div class="input-box">
                <b-form-input id="nameInput" v-model="name" type="text" class="bg-secondary"></b-form-input>
              </div>
            </b-input-group>
          </b-row>

          <b-button type="submit" variant="primary" :disabled="selectedFolder.files.length === 0 || errorMsg != null">Submit</b-button>
        </b-form>
      </div>

    </b-container>
  </div>
</template>

<script>
import FolderBrowser from '../components/FolderBrowser.vue'
import axios from 'axios'
import { mapGetters } from 'vuex'
export default {
  name: "maker",
  components: {
    "folder-browser": FolderBrowser
  },
  data() {
    return {
      // Error message that we want to display (if any)
      errorMsg: null,
      // Should the UI show a 'please refresh' msg when showing the error
      errorShowRefresh: true,
      folderID: null,
      folders: null,
      selectedFolder: {
        name: null,
        files: []
      },
      SJReadsCutOff: 10,
      SJReadsSample: 2,
      TPMCutOff: 1,
      TPMSample: 2,
      fragmentLen: 0.7,
      antiSenseLen: 0.5,
      prefix: "G",
      SJReadsCutOffTip: "A splice junction must be supported by at least this number of reads.",
      SJReadsSampleTip: "The minimum reads support for the splice junction must occur at least this number of samples.",
      TPMCutOffTip: "A expressed transcript must have at least this number of TPMs.",
      TPMSampleTip: "The TPM support for an expressed transcript must occur at least this number of samples.",
      fragmentLenTip: "A fragment transcript in the gene model will be filtered if the length is less than a defined percentage of the gene length. Value must be within 0 and 1.",
      antiSenseLenTip: "A monoexonic-antisense transcript in the gene model will be filtered if the transcript is in the opposite strand and the length is less than this percentage of the gene length. Value must be within 0 and 1.",
      prefixTip: "Prefix to assign to the genes and transcripts IDs in the final RTD.",
      nameCheck: false,
      name: ""
    }
  },

  computed: {
    ...mapGetters({token: "token"})
  },

  mounted() {
    axios.get("/folders?stage=sr4", 
      {
        params: {token: this.token },
        headers: {"Content-Type": "application/json"}
      }).then(
        function(response) {
          this.folders = response.data;
          this.onLoad();
        }.bind(this)
      )
      .catch(error => {
        console.log(error)
        this.errorMsg = "Unable to retrieve a list of folders.";
      });
  },

  methods: {
    onLoad: function() {
      if(this.$route.params.resubmitData != null && this.$route.params.resubmitData != undefined)
      {
        this.SJReadsCutOff = this.$route.params.resubmitData.SJReadsCutOff;
        this.SJReadsSample = this.$route.params.resubmitData.SJReadsSample;
        this.TPMCutOff = this.$route.params.resubmitData.TPMCutOff;
        this.TPMSample = this.$route.params.resubmitData.TPMSample;
        this.antiSenseLen = this.$route.params.resubmitData.antiSenseLen;
        this.fragmentLen = this.$route.params.resubmitData.fragmentLen;
        this.prefix = this.$route.params.resubmitData.prefix;
        this.folderID = this.$route.params.resubmitData.assemblyFolder;
        
        if(this.folderID !== undefined && this.folderID !== null)
        {
          this.folders.forEach(folder => {
            if((folder.id.localeCompare(this.folderID) == 0))
            {
              this.folderSelected(folder);
            }
          });
        }
      }
      
    },

    folderSelected: function(folder) {
      this.selectedFolder = folder;
    },

    submitJob: function() {
      var custName = null;
      if(this.nameCheck) {
        custName = this.name;
      }

      axios.get("/shortread/maker", {
        params: {
          SJReadsCutOff: this.SJReadsCutOff,
          SJReadsSample: this.SJReadsSample,
          TPMCutOff: this.TPMCutOff,
          TPMSample: this.TPMSample,
          fragmentLen: this.fragmentLen,
          antiSenseLen: this.antiSenseLen,
          token: this.token,
          assemblyFolderID: this.selectedFolder.id,
          prefix: this.prefix,
          keep: this.keep,
          name: custName
        },
        headers: {"Content-Type": "application/json"}
        }).then(
          function(response) {
            this.data = response.data;

            this.$router.push({ name: 'tracking', params: { folderID: response.data.makerFolderID }})

          }.bind(this)
        )
        .catch(error => {
          console.log(error)
          this.errorMsg = "ERROR";
        });
    }
  }
}
</script>

<style scoped>
.error {
  color: #ff0000;
}
img {
  max-width: 400px;
}
</style>